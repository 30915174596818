import styled from 'styled-components';
import BackWebp from '../../../../assets/leaderboard/background.webp'
import CloseSvg from '../../../../assets/buttons/close.svg'

export const Wrapper = styled.div`
  display: flex;
  transition: all 0.3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100%;
  background-color: rgba(3, 5, 7, 0.85);
  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {
    align-items: end;
  }
`;

export const Modal = styled.div`
  background-color: #2D1804;
  height: 80%;
  max-width: 741px;
  width: 100%;
  position: relative;
  background-image: url(${BackWebp});
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px;

  @media (max-width: 800px) {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0px;
  }
`;

export const CloseButton = styled.div<{ $isWebView: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 40px;
  right: 40px;
  width: 24px;
  height: 24px;
  background-image: url(${CloseSvg});
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  transition: all 0.1s ease-out;
  z-index: 99;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 800px) {
    top: 30px;
    right: 30px;
    ${props => props.$isWebView && `
      top: 60px;
    `}
  }
`;


export const Title = styled.span<{ $isWebView: boolean }>`
  color:  rgba(255, 225, 168, 1);
  font-family: 'Inknut Antiqua', serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  user-select: none;
  -webkit-user-select: none;
  margin-top: 70px;
  margin-bottom: 10px;
  @media (max-width: 800px) {
    font-size: 24px;
    line-height: 28px;
  }
  cursor: default;
  ${props => props.$isWebView && `
    margin-top: 100px;
  `}
`;

export const SubTitle = styled.p`
  color:  white;
  cursor: default;
  font-family: 'Inknut Antiqua', serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  user-select: none;
  -webkit-user-select: none;
  padding: 0 30px;
  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 21px;
  }
`;


export const ContentWrapper = styled.div`
  cursor: default;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(67, 49, 34, 1);
  }
`




