import styled from 'styled-components';

export const Wrapper = styled.div<{ $isVisible: boolean }>`
  display: none;
  ${props => props.$isVisible && `
    display: flex;
  `}
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99999;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
`;
