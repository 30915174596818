import styled from 'styled-components';
import BackWebp from '../../../../assets/leaderboard/background.webp'
import ButtonPng from '../../../../assets/buttons/button.webp'
import ActiveButtonPng from '../../../../assets/buttons/active_button.webp'

export const Wrapper = styled.div`
  display: flex;
  transition: all 0.3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100%;
  background-color: rgba(3, 5, 7, 0.85);
  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {
    align-items: end;
  }
`;

export const Modal = styled.div`
  background-color: #2D1804;
  max-width: 500px;
  width: 100%;
  position: relative;
  background-image: url(${BackWebp});
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: 800px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
  }
`;



export const Title = styled.span`
  color:  white;
  font-family: 'Inknut Antiqua', serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  user-select: none;
  -webkit-user-select: none;
  margin-top: 24px;
  @media (max-width: 800px) {
    font-size: 24px;
    line-height: 28px;
  }
  cursor: default;

`;

export const SubTitle = styled.span`
  color:  white;
  cursor: default;
  font-family: 'Inknut Antiqua', serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 19px;
  margin: 10px 24px 24px;
  user-select: none;
  -webkit-user-select: none;
  @media (max-width: 800px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const AcceptButton = styled.button`
  background: transparent;
  border: 0;
  -webkit-tap-highlight-color: transparent;
  height: 86px;
  width: 311px;
  background-image: url(${ButtonPng});
  background-size: cover;
  cursor: pointer;
  z-index: 10;
  display: flex;
  margin-bottom: 32px;
  justify-content: center;
  align-items: center;
  z-index: 55;
  user-select: none;
  -webkit-user-select: none;
  transition: all 0.5s ease-out;
  position: relative;

  @media (pointer: fine) {
    &:hover {
      &:after {
        background: radial-gradient(closest-side,rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        height: 86px;
        width: 311px;
        top: 0;
      }
      span {
        color: #FCD58C;
      }
    }
  }

  &:active {
    background-image: url(${ActiveButtonPng});
    &:after {
      background: radial-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0));
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
    }
    span {
      color: #FFE1A8;
      text-shadow: none;
    }
  }

  &:disabled {
    pointer-events: none;
    &:after {
      background: radial-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0));
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
    }
    span {
      color: rgba(255, 225, 168, 0.5);
      text-shadow: none;
    }
  }

  @media (max-width: 800px){
    height: 68px;
    width: 250px;
    top: inherit;
    left: calc(50% - 125px);
    bottom: calc(50% - 330px);
  }
`;


export const ButtonText = styled.span`
  transition: all 0.2s linear;
  color: rgba(255, 225, 168, 1);
  text-shadow: 4px 4px 4px rgba(25, 46, 51, 1);
  font-family: 'Inknut Antiqua', serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 38px;
  user-select: none;
  -webkit-user-select: none;
  @media (max-width: 800px) or (max-height: 864px) {
    font-size: 20px;
    line-height: 28px;
  }
`;
