export const sendGtagEvent = (eventName: string, configName?: string) => {
  const eventData = {
    send_to: 'G-DHV3LZW0XL',
    client_user_agent: window.navigator.userAgent,
    config_name: configName,
    project: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  };

  //@ts-ignore
  if (window.gtag) {
    console.log('event - ', eventName);
    // console.log(eventData);

    //@ts-ignore
    gtag('event', eventName, eventData);
  }
}
