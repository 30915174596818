import * as S from './CookiesAccept.styles';

export function CookiesAccept({ onClose }: { onClose: () => void }) {
  return (
    <S.Wrapper>
      <S.Modal>
        <S.Title>Cookies</S.Title>
        <S.SubTitle>
          To provide you with an enhanced and personalized experience, we use profiling cookies for content and
          advertising tailored specifically to you. By selecting "Accept all Cookies," you contribute to a seamless and
          customized browsing journey. Your privacy and preferences are our priority.
        </S.SubTitle>
        <div>
          <S.AcceptButton onClick={onClose}>
            <S.ButtonText>Accept all</S.ButtonText>
          </S.AcceptButton>
        </div>
      </S.Modal>
    </S.Wrapper>
  );
}
