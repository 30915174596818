import * as S from './HowToPlay.styles';
import { useGameState } from '../../../../providers/GameProvider.hooks';

export function HowToPlay({ onClose, isWebView }: { onClose: () => void; isWebView: boolean }) {
  const { config } = useGameState();

  return (
    <S.Wrapper>
      <S.Modal>
        <S.CloseButton $isWebView={isWebView} onClick={onClose}></S.CloseButton>
        <S.Title $isWebView={isWebView}>{config.howToPlayTitle}</S.Title>
        <S.ContentWrapper>
          {config.howToPlayText.map((el) => (
            <S.SubTitle>{el}</S.SubTitle>
          ))}
        </S.ContentWrapper>
      </S.Modal>
    </S.Wrapper>
  );
}
