export const getGtagId = (type: string) => {
  return new Promise<string>(async (resolve) => {
    if (process.env.REACT_APP_GTAG_EVENTS_ID) {
      //@ts-ignore
      gtag('get', process.env.REACT_APP_GTAG_EVENTS_ID, type, resolve);
    } else {
      resolve('');
    }
  });
}
