import crypto from 'crypto'
import { Buffer } from 'buffer';

window.Buffer = window.Buffer || require("buffer").Buffer;

export function getCookie(cname: string) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}


export function encrypt(text: string) {
  let pass = process.env.REACT_APP_SECRET_KEY || '';
  const algorithm = 'aes-256-cbc';
  let cipher = crypto.createCipheriv(algorithm, pass, Buffer.from(pass.substring(3, 19)));
  cipher.setAutoPadding(true);
  let crypted = cipher.update(text, 'utf8', 'base64');
  crypted += cipher.final('base64');
  return crypted;
}

export function decrypt(text: string) {
  let pass = process.env.REACT_APP_SECRET_KEY || '';
  const algorithm = 'aes-256-cbc';
  let decipher = crypto.createDecipheriv(algorithm, pass, Buffer.from(pass.substring(3, 19)));
  decipher.setAutoPadding(true);
  let dec = decipher.update(text, 'base64', 'utf8');
  dec += decipher.final('utf8');
  return dec;
}
