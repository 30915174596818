import * as S from './Leaderboard.styles';
import { useGameState } from '../../../../providers/GameProvider.hooks';
import Leader1Webp from '../../../../assets/leaderboard/leader_1.webp';
import Leader2Webp from '../../../../assets/leaderboard/leader_2.webp';
import Leader3Webp from '../../../../assets/leaderboard/leader_3.webp';

export function Leaderboard({ onClose, isWebView }: { onClose: () => void; isWebView: boolean }) {
  const { config, totalWinAmount } = useGameState();

  const getTable = () => {
    const tableWithUser = [
      ...config.leaderboard,
      {
        name: config.leaderboardUserName,
        score: totalWinAmount,
      },
    ];

    return tableWithUser.sort((a, b) => b.score - a.score);
  };

  const getBgImage = (index: number) => {
    if (index === 0) {
      return Leader1Webp;
    }
    if (index === 1) {
      return Leader2Webp;
    }
    if (index === 2) {
      return Leader3Webp;
    }
    return '';
  };

  return (
    <S.Wrapper>
      <S.Modal>
        <S.CloseButton $isWebView={isWebView} onClick={onClose}></S.CloseButton>
        <S.Title $isWebView={isWebView}>{config.leaderboardTitle}</S.Title>
        <S.SubTitle>{config.leaderboardSubTitle}</S.SubTitle>
        <S.TableHeader>
          <div>Rank</div>
          <div>User</div>
          <div>Total earnings</div>
        </S.TableHeader>
        <S.TableWrapper>
          <S.Table>
            {getTable().map((el, index) => (
              <S.Row
                $backImg={getBgImage(index)}
                className={el.name === config.leaderboardUserName ? 'selected' : undefined}
                key={el.name}
              >
                <div>{index + 1}</div>
                <div>{el.name}</div>
                <div>
                  <S.Coin className="coin" />
                  {el.score}
                </div>
              </S.Row>
            ))}
          </S.Table>
        </S.TableWrapper>
      </S.Modal>
    </S.Wrapper>
  );
}
