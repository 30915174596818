import React from 'react';
import * as S from './GameMachine.styles';

export const SYMBOL_HEIGHT = 106;
const TOTAL_SYMBOLS = 9;
const MULTIPLIER = SYMBOL_HEIGHT * TOTAL_SYMBOLS * 2;

export class Spinner extends React.Component<{
  timer: number;
  finishNumber: number;
  onFinish: (value: number) => void;
  isWinningRound: boolean;
}> {
  state = {
    position: 0,
  };

  spin() {
    const toZero = this.state.position % (SYMBOL_HEIGHT * TOTAL_SYMBOLS);
    const finishAt = this.props.finishNumber * SYMBOL_HEIGHT;
    const moveTo = this.state.position + MULTIPLIER - toZero + finishAt;

    this.setState({
      position: moveTo,
    });

    setTimeout(() => {
      let currentPosition = this.state.position % (SYMBOL_HEIGHT * TOTAL_SYMBOLS);
      this.props.onFinish(currentPosition);
    }, this.props.timer);
  }

  render() {
    return (
      <S.Icons
        $isWinningRound={this.props.isWinningRound}
        $seconds={this.props.timer / 1000}
        style={{ backgroundPositionY: this.state.position }}
      ></S.Icons>
    );
  }
}
