import { GameContext } from './GameProvider';
import React from 'react';

export function useGameState() {
  const context = React.useContext(GameContext);

  if (!context) {
    throw new Error('use only context provider!');
  }

  return context;
}
