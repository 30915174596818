import * as S from './Modal.styles';
import { useGameState } from '../../../../providers/GameProvider.hooks';
import { decrypt, encrypt, getCookie } from '../../../../utils/encrypt';
import { useState } from 'react';
import { getGtagId } from '../../../../utils/gtag';

export function Modal() {
  const { config, isShowModal, setShowModal } = useGameState();
  const [isDisabled, setDisabled] = useState(false);

  async function handleClaim() {
    if (isDisabled) {
      return;
    }
    setDisabled(true);

    const userIpAddress = await fetch(`${window.location.origin}/remote-addr`)
      .then(async (res) => await res.json())
      .then((res: { ip: string }) => res.ip);

    const gtagSessionId = await getGtagId('session_id');
    const gtagClientId = await getGtagId('client_id');

    const utm_campaign = new URLSearchParams(window.location.search).get('utm_campaign');

    const body = {
      client_ip_address: userIpAddress,
      client_user_agent: window.navigator.userAgent,
      fbc: getCookie('_fbc'),
      fbp: getCookie('_fbp'),
      timestamp: (Date.now() / 1000).toFixed(),
      action_source: config.link,
      project: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      gtag_client_id: gtagClientId,
      gtag_session_id: gtagSessionId,
      ...(utm_campaign && { utm_campaign }),
    };

    await fetch(`${process.env.REACT_APP_RECORD_USER_DATA_URL}/recordUserData`, {
      method: 'POST',
      body: encrypt(JSON.stringify(body)),
    })
      .then(async (res) => {
        const data = await res.text();
        const userId = JSON.parse(decrypt(data)).result.id;

        const subId = [userId, process.env.REACT_APP_PIXEL_ID, process.env.REACT_APP_FIREBASE_PROJECT_ID].join('_');

        const link =
          config.link +
          '?' +
          new URLSearchParams({
            subId1: subId,
            utm_term: subId,
            utm_affid: process.env.REACT_APP_UTM_AFF_ID ?? ''
          }).toString();

        window.open(link, '_self');
        setDisabled(false);
      })
      .then((result) => result)
      .catch((error) => {
        console.log('Get unique id error', error);
        fetch(`${window.location.origin}/event`, {
          method: 'POST',
          body: JSON.stringify({
            userAgent: window.navigator.userAgent,
            event: 'recordUserData error',
            eventCategory: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            project: window.location.origin,
            time: Date.now(),
            body: body,
            error: JSON.stringify(error),
            timeDifference: Date.now() - Number(body.timestamp) * 1000,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }).finally(() => {
          window.open(config.link, '_self');
          setDisabled(false);
        });
      });
  }

  return (
    <S.Wrapper $isVisible={isShowModal}>
      <S.Modal>
        {!config.closeButtonHidden && (
          <S.CloseButton
            onClick={() => {
              setShowModal(false);
            }}
          />
        )}
        <S.Title>{config.titleText}</S.Title>
        <S.SubTitle>{config.subTitleText}</S.SubTitle>
        <S.ClaimButton id="modal-cashout-button" onClick={isDisabled ? undefined : handleClaim}>
          <S.ButtonText>{config.claimButtonText}</S.ButtonText>
        </S.ClaimButton>
      </S.Modal>
    </S.Wrapper>
  );
}
